import React, { useState, useRef, useEffect } from "react";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import '../assets/styles/components/button.scss';

const Button = ({text, variant, margin = true, type = 'button', disabled = false, form = null, hoverable = true, small = false}) => {
  const [isHovered, setIsHovered] = useState(false);
  const textRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const currentText = textRef.current;
    const currentIcon = iconRef.current;

    if (!hoverable) return;

    if (currentText && currentIcon) {
      const margin = small ? 17.5 : 25;

      if (isHovered) {
        currentIcon.style.transform = `translateX(${currentText.offsetWidth + margin}px)`
      } else {
        currentIcon.style.transform = `translateX(0)`
      }
    }
  }, [textRef, iconRef, hoverable, small, isHovered]);

  let className = '';

  switch (variant) {
    case 'primary':
      className = 'primary';
      break;
    case 'secondary':
      className = 'secondary';
      break;
    case 'accent':
      className = 'accent';
      break;
    default:
      className = 'primary';
      break;
  }

  return (
    <button form={form} disabled={disabled} type={type} className={`button button-${className}${margin ? ' mt' : ''}${hoverable ? '' : ' disable-hover'}${small ? ' is-small' : ''}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className='button-content'>
        <ArrowRightAltIcon ref={iconRef} />
        <span ref={textRef}>{text}</span>
      </div>
    </button>
  )
}

export default Button
