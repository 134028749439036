import React, { useRef, useEffect } from 'react';
import { colors } from "../common/Theme"
import Button from '../components/button'
import {
  Container,
  Typography,
  makeStyles,
  Card,
  CardContent
} from '@material-ui/core'
import { Link } from 'gatsby'

import '../assets/styles/components/contactUs.scss';

const useStyles = makeStyles({
  contactUsContainer: {

  },
  contactUsMain: {
    fontSize: '2rem',
    fontWeight: '400',
    lineHeight: '1.235',
  },
  contactUsSub: {
    marginBottom: '1.2rem',
    fontWeight: '300',
    fontSize: '1.1rem'
  },
  card: {
    backgroundColor: colors.anthracite,
    marginBottom: 0,
  }
});

const ContactUs = ({mt = false}) => {
  const classes = useStyles();

  const contactRef = useRef(null);

  const contactUsCallbackFunction = (entries) => {
    entries.forEach(entry => {
      if (entry.boundingClientRect.y <= 0) return;

      entry.target.style.opacity = 0.25 + (0.75 * entry.intersectionRatio);
      entry.target.style.transform = `scale(${0.9 + (0.1 * entry.intersectionRatio)}, ${0.9 + (0.1 * entry.intersectionRatio)}`;
    })
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px 0px -0px 0px',
      threshold: new Array(101).fill(0).map((v, i) => i * 0.01),
    }
    const observer = new IntersectionObserver(contactUsCallbackFunction, options);

    const contactCurrent = contactRef.current;

    if (contactCurrent) observer.observe(contactCurrent);

    return () => {
      if (contactCurrent) observer.unobserve(contactCurrent);
    }
  }, [contactRef]);

  return (
    <section className={`contact-us-section${mt ? ' mt' : ''}`}>
      <Container >
        <div className='contact-us'>
          <Card classes={{root: classes.card}} elevation={0} className='contact-us-card' ref={contactRef}>
            <CardContent className='contact-us-card-content'>
              <Typography color='textSecondary' classes={{root: classes.contactUsMain}}>
                Ready to Talk ?
              </Typography>
              <Link to='/contact'>
                <Button variant='secondary' text='Start a project' />
              </Link>
            </CardContent>
          </Card>
        </div>
      </Container>
    </section>
  )
}

export default ContactUs
