import React from 'react';
import {
  Container,
  Typography,
  Grid,
  makeStyles,
  Card,
  CardContent
} from '@material-ui/core'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import { createContent, theme } from "../common/Theme"
import ContactUs from "../components/contactUs"
import Button from "../components/button"

import '../assets/styles/pages/services.scss'

const useStyles = makeStyles({
  servicesContainer: {},
  gridItemLeft: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
      marginLeft: 'auto'
    }
  },
  gridItemRight: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
      marginRight: 'auto'
    }
  },
  gridItemImage: {
    width: '100%'
  },
  cardLeft: {
    backgroundColor: 'transparent',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '6rem',
      paddingBottom: '6rem',
      paddingRight: '3rem',
    }
  },
  cardRight: {
    backgroundColor: 'transparent',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '6rem',
      paddingBottom: '6rem',
      paddingLeft: '3rem'
    }
  },
  paragraph: {
    marginBottom: '1rem'
  }
})

const ServicesPage = () => {
  const content = createContent();

  const classes = useStyles();

  return (
    <Layout>
      <section id='services-section'>
        <Container classes={{root: content.content}}>
          <div className='inner-container'>
            <Grid container spacing={6}>
              <Grid item md={4} sm={12} xs={12} className='side-heading-header'>
                <Typography variant='h3'>What we do</Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={12} className='side-heading-content'>
                <Typography classes={{root: classes.paragraph}} className='text-large'>
                  We create intuitive digital designs, develop bespoke software solutions and help businesses overcome their technical challenges with a user-first mentality to enrich people’s lives.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <section id='design-section'>
        <Grid container spacing={0}>
          <Grid item md={6} sm={12} classes={{root: classes.gridItemLeft}}>
            <Card elevation={0} id='design-card' classes={{root: classes.cardLeft}}>
              <CardContent>
                <Typography variant='h3' color='textSecondary'>Design</Typography>
                <Typography color='textSecondary' classes={{root: classes.paragraph}}>
                  Clear communication in the digital world can be challenging.
                  That’s why we take a user-centric approach in designing websites and user interfaces to bridge the gap between your users and the information they need.
                </Typography>
                <Typography color='textSecondary'>
                  With a user-centric and mobile-first approach we build intuitive interfaces that engage and communicate clearly on all devices.
                </Typography>
                <Link to='/services/design'>
                  <Button text='Go to Design' variant='secondary' />
                </Link>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6} sm={12} classes={{root: classes.gridItemImage}}>
            <div id='design-image' />
          </Grid>
        </Grid>
      </section>
      <section id='development-section'>
        <Grid container spacing={0}>
          <Grid item md={6} sm={12} classes={{root: classes.gridItemImage}}>
            <div id='development-image' />
          </Grid>
          <Grid item md={6} sm={12} classes={{root: classes.gridItemRight}}>
            <Card elevation={0} id='development-card' classes={{root: classes.cardRight}}>
              <CardContent>
                <Typography variant='h3' color='textSecondary'>Development</Typography>
                <Typography color='textSecondary' classes={{root: classes.paragraph}}>
                  We develop custom software solutions tailor-made to your use case. By leveraging the latest tools, technologies and best-practices we create bespoke software systems of all shapes and sizes across different industries and for all platforms.
                </Typography>
                <Typography color='textSecondary'>
                  Whether you need an end-to-end solution or want to expand on your existing tools, we help you in finding the best solution for your use case.
                </Typography>
                <Link to='/services/development'>
                  <Button text='Go to Development' variant='secondary' />
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </section>
      <section id='consulting-section'>
        <Grid container spacing={0}>
          <Grid item md={6} sm={12} classes={{root: classes.gridItemLeft}}>
            <Card elevation={0} id='consulting-card' classes={{root: classes.cardLeft}}>
              <CardContent>
                <Typography variant='h3' color='textSecondary'>Consulting</Typography>
                <Typography color='textSecondary' classes={{root: classes.paragraph}}>
                  As many businesses are adopting digital as the new normal and the key driver for growth we help them navigate the ever-evolving digital landscape.
                </Typography>
                <Typography color='textSecondary'>
                  Does your team need a temporary boost in capacity or technical expertise, do you need help making sense of all your data, do you want to quickly prototype new ideas and opportunities or do you need help in migrating your existing tools to the cloud? We are here to help.
                </Typography>
                <Link to='/services/consulting'>
                  <Button text='Go to Consulting' variant='secondary' />
                </Link>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6} sm={12} classes={{root: classes.gridItemImage}}>
            <div id='consulting-image' />
          </Grid>
        </Grid>
      </section>

      <ContactUs mt />
    </Layout>
  )
}

export default ServicesPage;
